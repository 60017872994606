import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as Models from '../../../models/models-index';
import { HelpTextService } from '../../../services/help/helpText.service';
import { FilterService } from '../../../services/services-index';
import { map, Observable, tap } from 'rxjs';
import { ReportViewFilterSelection } from '../../../filter/store';
import { TypeCheck } from '../../../hy-filter-panel-module/Utils';

@Component({
  selector: 'kpi-metric-detailed',
  templateUrl: './kpi-metric-detailed.component.html',
  styleUrls: ['../panel-elements.scss', './kpi-metric-detailed.scss'],
})
export class KpiMetricDetailedComponent implements Models.ElementComponent, OnInit {
  @Input() dataSet: Models.DataSet;
  @Input() settings: Models.ElementSettings;
  @Input() viewContext?: { [key: string]: string; };

  metricValue;
  metricValuePreviousPeriod;
  metricValuePreviousYear;
  metricValuePOP;
  metricValueYOY;
  metricLabel;
  title;
  titleIconClasses;
  subtitleOne;
  subtitleTwo;
  subtitleTwoLabel: string;
  metricValuePOPValue: number;
  metricValueYOYValue: number;

  constructor(
    private helpTextService: HelpTextService) {
  }

  ngOnInit() {
    //determine if we should use the legacy approach with settings.metricName or the newer approach based on the metrics array in settings
    if (this.settings.metrics && this.settings.metrics.length > 0) {
      this.getValues();
    } else {
      this.getValuesByLegacy();
    }
  }

  getValues() {
    const row = this.dataSet.rows[0];

    const metric = this.settings.metrics[0]; // Assuming you want the first metric
    const metricIndex = this.dataSet.columns.findIndex(col => col.name === metric.name);
    const metricPreviousPeriodIndex = this.dataSet.columns.findIndex(col => col.name === metric.previousPeriod);
    const metricPreviousYearIndex = this.dataSet.columns.findIndex(col => col.name === metric.previousYear);
    const metricIndexPOP = this.dataSet.columns.findIndex(col => col.name === metric.pop);
    const metricIndexYOY = this.dataSet.columns.findIndex(col => col.name === metric.yoy);

    this.metricValue = row[metricIndex].label ?? row[metricIndex].value;
    this.metricValuePreviousPeriod = row[metricPreviousPeriodIndex]?.label;
    this.metricValuePreviousYear = row[metricPreviousYearIndex]?.label;
    this.metricValuePOPValue = row[metricIndexPOP]?.value as number;
    this.metricValueYOYValue = row[metricIndexYOY]?.value as number;
    this.metricValuePOP = row[metricIndexPOP]?.label;
    this.metricValueYOY = row[metricIndexYOY]?.label;
    this.title = this.settings.title ?? this.dataSet.columns[metricIndex].displayName ?? this.dataSet.columns[metricIndex].name;
    this.titleIconClasses = this.settings.titleIconClasses;
    this.subtitleOne = this.metricValuePreviousPeriod;
  }

  getValuesByLegacy() {
    const row = this.dataSet.rows[0];
    let metricIndex = this.dataSet.columns.findIndex(col => col.name === this.settings.metricName);

    if (metricIndex === -1) {
      console.error(`Metric ${this.settings.metricName} not found in the dataset.`);
      return;
    }

    let metricPreviousPeriodIndex = this.dataSet.columns.findIndex(col => col.name === this.settings.metricNamePreviousPeriod);
    let metricPreviousYearIndex = this.dataSet.columns.findIndex(col => col.name === this.settings.metricNamePreviousYear);
    let metricIndexPOP = this.dataSet.columns.findIndex(col => col.name === this.settings.metricNamePOP);
    let metricIndexYOY = this.dataSet.columns.findIndex(col => col.name === this.settings.metricNameYOY);

    this.metricValue = row[metricIndex].label ?? row[metricIndex].value;
    this.metricValuePreviousPeriod = this.dataSet.rows[0][metricPreviousPeriodIndex]?.label;
    this.metricValuePreviousYear = this.dataSet.rows[0][metricPreviousYearIndex]?.label;
    this.metricValuePOPValue = this.dataSet.rows[0][metricIndexPOP]?.value as number;
    this.metricValueYOYValue = this.dataSet.rows[0][metricIndexYOY]?.value as number;
    this.metricValuePOP = this.dataSet.rows[0][metricIndexPOP]?.label;
    this.metricValueYOY = this.dataSet.rows[0][metricIndexYOY]?.label;
    this.title = this.settings.title ?? this.dataSet.columns[metricIndex].displayName ?? this.dataSet.columns[metricIndex].name;
    this.titleIconClasses = this.settings.titleIconClasses;
    this.subtitleOne = this.metricValuePreviousPeriod;
  }

  getTrendArrowIconClass(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);

    if (isTrendUp === null || trend === 0) {
      return 'fa fa-horizontal-rule';
    } else if (trend > 0) {
      return 'fa fa-chevron-circle-up chevron-icon';
    } else if (trend < 0) {
      return 'fa fa-chevron-circle-down chevron-icon';
    }
  }

  getTrendIconColor(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);

    if (isTrendUp === null) {
      return 'bg-yellow';
    } else if (!isTrendUp) {
      return 'bg-red';
    } else if (!!isTrendUp) {
      return 'bg-green';
    }
  }

  getTrendColor(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);
    if (isTrendUp == null) {
      return 'text-yellow';
    } else if (!!isTrendUp) {
      return 'text-green';
    } else if (!isTrendUp) {
      return 'text-red';
    }
  }

  isTrendUp(value: number) {
    if (!value || value === 0)
      return null;

    return value > 0
      ? this.settings.metricInverted ? false : true
      : this.settings.metricInverted ? true : false;
  }

  openHelpTextClicked(): void {
    this.helpTextService.openHelp(this.settings.helpTextKey, this.settings.helpTextTitle);
  }

}
