import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import * as Models from '../../../models/models-index';
import { DataSet } from '../../../models/report-view.models';
import { map, Observable, tap } from 'rxjs';
import { FilterService } from '../../../services/services-index';
import { ReportViewFilterSelection } from '../../../filter/store';
import { TypeCheck } from '../../../hy-filter-panel-module/Utils';

@Component({
  selector: 'kpi-metric',
  templateUrl: './kpi-metric.component.html',
  styleUrls: ['../panel-elements.scss','./kpi-metric.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class KpiMetricComponent implements Models.ElementComponent, OnInit {
  @Input() dataSet: DataSet;
  @Input() settings: any;
  @Input() viewContext?: { [key: string]: string; };

  get hasDynamicLink() {
    return !!this.settings.options?.['linkTemplate'];
  }

  metricValue;
  metricTrend;
  metricLabel;
  subtitleOne;
  subtitleOneLabel: string;

  constructor(
    private filterService: FilterService
  ) {
  }

  ngOnInit() {
    const metricIndex = this.dataSet.columns.findIndex(col => col.name === this.settings.metricName);
    const metricTrendIndex = this.dataSet.columns.findIndex(col => col.name === this.settings.metricTrend);
    const metricPreviousPeriodIndex = this.dataSet.columns.findIndex(col => col.name === this.settings.metricNamePreviousPeriod);

    this.metricValue = this.dataSet.rows[0][metricIndex].label ?? this.dataSet.rows[0][metricIndex].value;
    this.metricTrend = this.dataSet.rows[0][metricTrendIndex].value;
    this.metricLabel = this.settings.displayName ?? this.dataSet.columns[metricIndex].displayName ?? this.dataSet.columns[metricIndex].name;

    const metricPreviousPeriod = this.dataSet.rows[0][metricPreviousPeriodIndex];
    this.subtitleOne = metricPreviousPeriod?.label ?? metricPreviousPeriod?.value;
    this.subtitleOneLabel = this.settings.subtitleOneLabel ?? "Prev. Period:";
  }

  getDynamicLinkUrl(): Observable<string> {
    if (!this.viewContext?.reportName) {
      throw new Error('[kpi-metric] viewContext.reportName is required');
    }
    return this.filterService.getDynamicLinkUrl(this.viewContext, this.settings.options['linkTemplate'], this.settings.options['linkKeyMap']);
  }

  getTrendArrowIconClass(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);

    if (isTrendUp === null || trend === 0) {
      return 'fa fa-horizontal-rule';
    } else if (trend > 0) {
      return 'fa fa-chevron-circle-up chevron-icon';
    } else if (trend < 0) {
      return 'fa fa-chevron-circle-down chevron-icon';
    }
  }

  getTrendIconColor(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);

    if (isTrendUp === null) {
      return 'bg-yellow';
    } else if (!isTrendUp) {
      return 'bg-red';
    } else if (!!isTrendUp) {
      return 'bg-green';
    }
  }

  getTrendColor(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);
    if (isTrendUp == null) {
      return 'text-yellow';
    } else if (!!isTrendUp) {
      return 'text-green';
    } else if (!isTrendUp) {
      return 'text-red';
    }
  }

  isTrendUp(value: number) {
    if (!value || value === 0)
      return null;

    return value > 0
      ? this.settings.metricInverted ? false : true
      : this.settings.metricInverted ? true : false;
  }

}
