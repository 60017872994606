<mat-card class="element-card kpi-metric-element mat-elevation-z1">
  <mat-card-title class="element-title">
    {{metricLabel}}
  </mat-card-title>
  <mat-card-content>
    <div class="element-card-content">
      <div class="kpi-metric-element-text">
        <b *ngIf="!hasDynamicLink" class="metric-value">{{metricValue}}</b>
        <a *ngIf="hasDynamicLink" [href]="getDynamicLinkUrl() | async" class="metric-value metric-value-link">{{metricValue}}</a>
        <i [ngClass]="[getTrendArrowIconClass(metricTrend), getTrendIconColor(metricTrend)]"></i>
      </div>
      <div *ngIf="subtitleOne" class="subtitle">
        <span class="subtitle-label">{{ subtitleOneLabel }}</span>
        <span class="subtitle-value">{{ subtitleOne }}</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>