import { ViewContainerRef } from "@angular/core";
import { DataSet, ElementComponent, ElementSettings, PanelConfiguration, ViewContext } from "../../models/report-view.models";
import { HelpTextService } from "../../services/services-index";

export class ElementBaseComponent implements ElementComponent{
    dataSet: DataSet;
    settings: ElementSettings;
    panelConfiguration?: PanelConfiguration;
    selectedMetric?: string;
    selectedMetrics?: string[];
    viewContext?: ViewContext;
    
    constructor(private helpTextService: HelpTextService) {}
  
    getTrendArrowIconClass(trend: number): string {
      const isTrendUp = this.isTrendUp(trend);
  
      if (isTrendUp === null || trend === 0) {
        return 'fa fa-horizontal-rule';
      } else if (trend > 0) {
        return 'fa fa-chevron-circle-up chevron-icon';
      } else if (trend < 0) {
        return 'fa fa-chevron-circle-down chevron-icon';
      }
    }
  
    getTrendIconColor(trend: number): string {
      const isTrendUp = this.isTrendUp(trend);
  
      if (isTrendUp === null) {
        return 'bg-yellow';
      } else if (!isTrendUp) {
        return 'bg-red';
      } else if (!!isTrendUp) {
        return 'bg-green';
      }
    }
  
    getTrendColor(trend: number): string {
      const isTrendUp = this.isTrendUp(trend);
      if (isTrendUp == null) {
        return 'text-yellow';
      } else if (!!isTrendUp) {
        return 'text-green';
      } else if (!isTrendUp) {
        return 'text-red';
      }
    }
  
    isTrendUp(value: number) {
      if (!value || value === 0)
        return null;
  
      return value > 0
        ? this.settings.metricInverted ? false : true
        : this.settings.metricInverted ? true : false;
    }
  
    openHelpTextClicked(): void {
      this.helpTextService.openHelp(this.settings.helpTextKey, this.settings.helpTextTitle);
    }
  }