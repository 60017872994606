import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter, ViewChild, Type, Renderer2, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, iif, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { DynamicComponentDirective } from '../../../directives/dynamic-component.directive';
import * as Models from '../../../models/models-index';
import { PanelElementResolverService } from '../panel-element-resolver.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import * as SharedServices from '../../../services/services-index';
import { BaseComponent } from '../../base.component';
import { AppState } from '../../../../_store/app-state.model';
import { Store } from '@ngrx/store';
import { FilterActions, FilterOptions } from '../../../filter/store';
import { Enums, IEnums } from '../../../enums/enums';
import { IRole } from '../../../models/models-index';
import { filter, map, tap, switchMap, catchError, takeUntil } from 'rxjs/operators';
import { ReportsService } from '../../../services/api/reports.service';
import { ExportCsv } from '../../../utilities/generate-csv';
import { AppSelectors } from '../../../../_store/selector-types';
import { convertOptionsToDictionary } from '../../panel-elements/panel-utils';
import { SelectableItem } from '../../nested-menu/nested-menu.component';
const clone = require('rfdc/default');

type SelectedConfiguration = { panelConfiguration: Models.PanelConfiguration, columnSet: string, options: Models.OptionModel[] }

@Component({
  selector: 'text-panel',
  templateUrl: './text-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TextPanelComponent extends BaseComponent implements OnInit, Models.PanelComponent {

  private _panelConfig: Models.Panel;
  @Input()
  set panelConfig(value: Models.Panel) {
    this._panelConfig = value;
  }
  @Input() dataSets: Models.DataSet[];
  @Input() rowPanelId: string;
  @Input() translationService?: Models.ITranslationService;
  @Input() viewContext?: { [key: string]: string };
  text: string;

  ngOnInit(): void {
    this.text = this._panelConfig.configurations[0].htmlContent;
  }
}
