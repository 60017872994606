import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicComponentDirective } from '../../../directives/dynamic-component.directive';
import * as Models from '../../../models/models-index';
import { ElementComponent } from '../../../models/models-index';
import { PanelElementResolverService } from '../panel-element-resolver.service';

@Component({
  selector: 'ghost-panel-full-width',
  templateUrl: './ghost-panel-full-width.component.html',
  styleUrls: ['./ghost-panel-full-width.component.scss']
})
export class GhostPanelFullWidthComponent implements OnInit, Models.PanelComponent {

  @ViewChild(DynamicComponentDirective, { static: true }) dynamicComponent!: DynamicComponentDirective;
  @Input() panelConfig: Models.Panel;
  @Input() dataSets: Models.DataSet[];
  @Input() rowPanelId: string;
  @Input() translationService?: Models.ITranslationService;
  @Input() viewContext?: { [key: string]: string };

  get panelConfigs() { return this.panelConfig.configurations }

  panelTitle;

  constructor(
    private resolverService: PanelElementResolverService,
    private renderer2: Renderer2,
    private router: Router) { }

  ngOnInit(): void {
    this.generateElements();
  }

  generateElements() {
    const config = this.panelConfig?.configurations[0];

    this.panelTitle;


    const viewContainerRef = this.dynamicComponent.viewContainerRef;
    viewContainerRef.clear();

    config?.elements.forEach(element => {
      const dataset = this.dataSets.find(ds => ds.name === element.settings.dataSet);
      const componentFactory = this.resolverService.resolveElementComponent(element.type)
      const componentRef = viewContainerRef.createComponent<ElementComponent>(componentFactory);
      componentRef.instance.dataSet = dataset; // we assume we only need one here
      componentRef.instance.settings = element.settings;
      componentRef.instance.viewContext = this.viewContext;
      this.renderer2.addClass(componentRef.location.nativeElement, 'ghost-panel-element');
      this.renderer2.setAttribute(componentRef.location.nativeElement, 'style', `width:100%; min-width: 0px;`);
    })
  }
}
