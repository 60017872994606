
import { Enums, IEnums } from '../../enums/enums';
import { } from '../cache/localCache.service';
import { LocaleService } from '../../locale/locale.service';
import { Injectable } from '@angular/core';
import * as Models from '../../models/models-index';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { tap, map, switchMap, filter } from 'rxjs/operators';
import { AppState } from '../../../_store/app-state.model';
import { Store, select } from '@ngrx/store';
import { AppSelectors } from '../../../_store/selector-types';
import { FilterBarService } from '../../filter/filter-bar.service';
import { ConfigurationService } from '../config/config.service';
import { FilterActions } from '../../filter/store/action-types';
import { FilterSelectors, ReportViewFilterSelection } from '../../filter/store';
import { TypeCheck } from '../../hy-filter-panel-module/Utils';
import { ViewContext } from '../../models/models-index';

export interface IFilterDates {
  startDate: Date;
  endDate: Date;
  previousStartDate: Date;
  previousEndDate: Date;
}

export interface IDaterangeRestrictions {
  restrict: boolean;
  numberOfMonthsToShow: number;
}

@Injectable({ providedIn: 'root'})
export class FilterService {

  private enums: IEnums = Enums;
  filterName: string;
  activePageTitle$ = new BehaviorSubject('Please set a page title');

  orgLookups: Models.IOrgLookup[];
  currentSalesMonth: Date;
  dayOfSalesMonth: number;
  locale: string;

  private secondaryNavResized = new Subject<string>();
  private daterangeRestrictions = new Subject<IDaterangeRestrictions>();

  secondaryNavResized$ = this.secondaryNavResized.asObservable();
  daterangeRestrictions$ = this.daterangeRestrictions.asObservable();

  currentFilterModel: Models.IFilterModel;
  defaultFilter: Models.IFilterModel;

  // Observables for requesting and returning filter breadcrumbs
  private filterBreadcrumbsRequested = new Subject<void>();
  private filterBreadcrumbsReturned = new Subject<string[]>();
  filterBreadcrumbsRequested$ = this.filterBreadcrumbsRequested.asObservable();
  filterBreadcrumbsReturned$ = this.filterBreadcrumbsReturned.asObservable();

  filter$: Observable<Models.IFilterModel>;
  currentReportName$: Observable<string>;

  // Auth/User info
  private authInfo: Models.IAuthenticationInfo;
  constructor(
    private store: Store<AppState>,
    private localeService: LocaleService,
    private authService: AuthenticationService,
    private filterBarService: FilterBarService,
    private configService: ConfigurationService) {
      this.filter$ = this.store.select(AppSelectors.selectCurrentRouteData).pipe(
        map(routeData => routeData.reportName),
        switchMap(reportName => this.store.select(AppSelectors.selectCurrentRouteData).pipe(
          map(routeData => routeData.useV5Filters),
          filter(usev5Filters => !usev5Filters),
          switchMap(() => this.filterBarService.getReportFilterModel(reportName))
        ))
      );
      // we can get this anywhere we need from this.filters$ or refrence this directly
      //  in this component - this is referenced in a lot of unused legacy / unconverted report functions
      this.filterBarService.getDefaultFilterModel().pipe(
        tap(defaultFilter => this.defaultFilter = defaultFilter)
      ).subscribe();

      this.localeService.locale$.pipe(tap(loc => {
        this.locale = loc;
      })).subscribe(),

      this.currentReportName$ = this.store.select(AppSelectors.selectCurrentRouteData).pipe(
        map(data => data.reportName || 'UNKNOWN')
      );
  }

  initialize() {
    this.authInfo = this.authService.getUser();
  }

  getCurrentFilterModel() {
    return this.currentFilterModel;
  }

  getDefaultFilterModel() {
    return this.defaultFilter;
  }

  setActivePageTitle(title: string) {
    this.activePageTitle$.next(title);
  }

  getActivePageTitle(): string {
    return '';
  }

  restrictDaterangeOptions(options: IDaterangeRestrictions) {
    this.daterangeRestrictions.next(options);
  }

  triggerNavResizeEvent(filterState: string) {
    this.secondaryNavResized.next(filterState);
  }

  // Requesting/returning filter breadcrumbs
  requestBreadcrumbString() {
    this.filterBreadcrumbsRequested.next();
  }

  returnBreadcrumbString(breadcrumbs: string[]) {
    this.filterBreadcrumbsReturned.next(breadcrumbs);
  }

  convertStringToStringArray(value: string): string[] {
    if (!value) {
      return null;
    } else {
      const array = value.split(',');
      return array;
    }
  }

  convertStringToNumberArray(value: string): number[] {
    if (!value) {
      return null;
    } else {
      const array = value.split(',').map(Number);
      return array;
    }
  }

  // initializeReportViewFilters(reportName: string, reportViewFilters: Models.ReportViewFilter[]) {
  //   this.store.dispatch(FilterActions.initializeReportViewFilters({ reportName, filters: reportViewFilters }));
  // }

  updateReportViewFilter(reportName: string, selection: ReportViewFilterSelection) {
    this.store.dispatch(FilterActions.updateReportViewFilterSelection({ reportName, selection }));
  }

  updateReportViewFilters(reportName: string, selections: ReportViewFilterSelection[]) {
    this.store.dispatch(FilterActions.updateReportViewFilterSelections({ reportName, selections }));
  }

  getReportViewFilters(reportName: string) {
    return this.store.select(FilterSelectors.selectReportViewFiltersForReport({ reportName }));
  }

  getReportViewFilterSelection(reportName: string, filterName: string) {
    return this.store.select(FilterSelectors.selectReportViewFilterSelection({ reportName, filterName }));
  }

  getReportViewFilterSelections(reportName: string) {
    return this.store.select(FilterSelectors.selectReportViewFilterSelections({ reportName }));
  }

  getReportViewFilterRequestModel(reportName: string): Observable<Models.ReportRequestModel> {
    return this.store.select(FilterSelectors.selectReportViewFilterSelections({ reportName })).pipe(
      filter(selections => ((selections || []).length > 0)),
      // tap(selections => console.log('[filter.service getReportViewFilterRequestModel] selections: ', selections)),
      map(selections => {
        const requestModel = <Models.ReportRequestModel>{ reportType: reportName, filters: { culture: this.locale == 'en' ? 'en-US' : this.locale } };
        const requestFilterSelections: Models.ReportFilterRequestSelection[] = [];
        selections?.forEach(selection => {
          const name = selection.item.name;

          requestFilterSelections.push(this.convertToReportFilterValue(selection));

        });

        return {...requestModel, filterSelections: requestFilterSelections};
      })
    );
  }

  getDynamicLinkUrl(viewContext: ViewContext, urlTemplate: string, keyMap: string): Observable<string> {
    if (!urlTemplate) {
      throw new Error('[FilterService] urlTemplate is required');
    }

    if (!!viewContext.legacyReport) {
      return this.filter$.pipe(
        map(filter => this.buildLegacyFilterUrl(filter, urlTemplate, keyMap))
        // tap(url => console.log('legacy url: ', url))
      );
    } else {
      return this.getReportViewFilterSelections(viewContext.reportName).pipe(
        map(selections => this.buildFilterUrl(selections, urlTemplate, keyMap))
      );
    }
  }

  transposeFilterModelToParameterMap(
    keyMap: string, 
    filterModel: Models.IFilterModel
  ): Record<string, string> {
      // Split the string into parts based on the comma
      const pairs = keyMap.split(',');

      const result: Record<string, any> = {};

      // Iterate over the pairs
      for (const pair of pairs) {
          // Remove square brackets and split by the pipe symbol
          const cleanedPair = pair.replace(/[\[\]]/g, ''); // Remove square brackets
          const [key, value] = cleanedPair.split('|');

          // Use the value to look up a property in the filter model
          if (filterModel[value] !== undefined) {
            if (value === 'startDate' || value === 'endDate') {
              //convert date to string formatted as 'YYYY-MM-DD'
              result[key] = new Date(filterModel[value]).toISOString().split('T')[0];
            }
            else
              result[key] = filterModel[value] ?? '';
          } else {
              result[key] = ''; // Handle missing properties gracefully
          }
      }

      return result;
  }

  replaceUrlTokens(url: string, dictionary: Record<string, any>): string {
      // Use a regular expression to match tokens in the format {tokenName}
      return url.replace(/\{([^}]+)\}/g, (match, token) => {
          // Replace the token with the value from the dictionary, or leave it as-is if not found
          return dictionary[token] !== undefined ? encodeURIComponent(dictionary[token]) : match;
      });
  }

  buildLegacyFilterUrl(filter: Models.IFilterModel, urlTemplate: string, keyMap: string): string {
    const parameterMap = this.transposeFilterModelToParameterMap(keyMap, filter);
    return this.replaceUrlTokens(urlTemplate, parameterMap);
  }

  getSelectionValueForUrl(selection: ReportViewFilterSelection): string[] {
    if (TypeCheck.isDateRangeValue(selection.item.value)) {
        return [selection.item.value.startDate,selection.item.value.endDate];
    } else if (TypeCheck.isHierarchyValueArray(selection.item.value)) {
        return [selection.item.value.map(h => h.key).join(',')];
    } else if (TypeCheck.isStringArray(selection.item.value)) {
        return [selection.item.value.join(',')];
    } else {
        throw new Error(`Unsupported filter value type: ${selection.item.value}`);
    }
  }

  transposeSelectionValuesToParameterMap(keyMap: string, selections: ReportViewFilterSelection[]): Record<string,string> {
    // Split the string into parts based on the comma
    const pairs = keyMap.split(',');

    const result: Record<string, any> = {};

    // Iterate over the pairs
    for (const pair of pairs) {
        // Remove square brackets and split by the pipe symbol
        const cleanedPair = pair.replace(/[\[\]]/g, ''); // Remove square brackets
        const [key, value] = cleanedPair.split('|');

        // Use the value to look up a property in the filter model
        if (value == 'startDate') {
          //get first selection that is of type date range and grab it's value's start date
          const selection = selections.find(s => TypeCheck.isDateRangeValue(s.item.value));
          if (selection && TypeCheck.isDateRangeValue(selection.item.value)) {
            result[key] = selection.item.value.startDate;
          }
        }
        else if (value == 'endDate') {
          //get first selection that is of type date range and grab it's value's end date
          const selection = selections.find(s => TypeCheck.isDateRangeValue(s.item.value));
          if (selection && TypeCheck.isDateRangeValue(selection.item.value)) {
            result[key] = selection.item.value.endDate;
          }
        }
        else {
          const selection = selections.find(s => s.name === value);
          if (selection) {
              result[key] = this.getSelectionValueForUrl(selection);
          } else {
              result[key] = null; // Handle missing properties gracefully
          }
        }
    }

    return result;
  }

  buildFilterUrl(selections: ReportViewFilterSelection[], urlTemplate: string, keyMap: string): string {
    const parameterMap = this.transposeSelectionValuesToParameterMap(keyMap, selections);
    return this.replaceUrlTokens(urlTemplate, parameterMap);
  }


  convertToReportFilterValue(filterSelection: ReportViewFilterSelection): Models.ReportFilterRequestSelection {
    if (TypeCheck.isDateRangeValue(filterSelection.item.value)) {
      return {
        name: filterSelection.name,
        value: {
          name: filterSelection.item.value.name,
          startDate: filterSelection.item.value.startDate,
          endDate: filterSelection.item.value.endDate
        }
      };
    } else if (TypeCheck.isHierarchyValueArray(filterSelection.item.value)) {
      return {
        name: filterSelection.item.value[0].name,
        value: <Models.ReportFilterHierarchyValue>{
          name: filterSelection.item.value[0].name,
          hierarchyTypeId: filterSelection.item.value[0].hierarchyTypeId,
          value: filterSelection.item.value.map(h => h.key).join(",")
        }
      };
    } else if (TypeCheck.isStringArray(filterSelection.item.value)) {
      return {
        name: filterSelection.name,
        value: filterSelection.item.value.join(",")
      };
    } else {
      return {
        name: filterSelection.name,
        value: filterSelection.item.value
      };
    }
  }

}
